const moment = require('moment');

/**
 * 扁平化转换
 * @param arr 输入集合
 * @param groupToItems 集合中的组提取子项集合的方法
 * @param itemToTarget 子项转化为目标的方法
 * @returns {*} 扁平化之后的转化结果
 */
export const flatConvent = (arr, groupToItems, itemToTarget) => {
    const convent = item => groupToItems(item) ? groupToItems(item).map(it => convent(it)) : itemToTarget(item);
    return arr.map(item => convent(item));
}

export const conventLocalDateTimeToMoment = arr => {
    if (arr) {
        // moment.js 的月份是 [0-11], 所以arr[1] = arr[1] - 1
        const cp = arr.slice();
        cp[1] -= 1;
        return moment(cp);
    }

    return null;
}

export const conventLocalDateTimeToISOString = arr => {
    const time = conventLocalDateTimeToMoment(arr);
    return time == null ? null : time.toISOString();
}