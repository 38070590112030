/**
 * 可编辑的输入框
 * 文本改变后, 可以提交变更
 *
 * @param props
 * @constructor
 */
import {Input} from "antd";
import {useState} from "react";
import {CheckCircleTwoTone} from "@ant-design/icons";
import axios from "axios";

export const EditableInput = props => {
    const text = props.text;
    const editable = props.editable;
    const updateUrl = props.updateUrl;
    const itemKey = props.itemKey;

    const [change, setChange] = useState(false);
    const [value, setValue] = useState(text);

    const valueChange = e => {
        setValue(e.target.value);

        setChange(e.target.value !== text);
    };

    const submit = () => {
        if (value === text) {
            setChange(false);
            return;
        }

        const data = {[itemKey]: value};
        axios.post(updateUrl, data).then(resp => {
            if (resp.data.ok) {
                console.log(`value ${text} update to ${value}`);
                props.flush();
                setChange(false);
            }
        });
    }

    if (!editable) {
        return <Input value={text} disabled={true}/>
    }

    return (
        <Input value={value}
               onChange={valueChange}
               suffix={change ? <CheckCircleTwoTone onClick={submit}/> : <div/>}
        />
    );

}