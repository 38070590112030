import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.min.css';
import {Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Radio, Row,} from 'antd';
import 'moment/locale/zh-cn';
import {conventLocalDateTimeToMoment} from "../util/CommonUtil";

const axios = require('axios');
const moment = require('moment');

const formRef = React.createRef();

export const TemplateForm = props => {

    const args = props.args;

    const defaultFromArgs = {
        name: '',
        submit: '',
        defaultValue: {},
        items: [],
    };

    const [formArgs, setFormArgs] = useState(defaultFromArgs);

    useEffect(() => {
        axios.get(args.fromApi).then(function (resp) {
            const data = resp.data.data;
            console.log('load form: ', data);
            setFormArgs(data)
            formRef.current.resetFields();
        });
    }, [args])

    const onFinish = (values) => {
        // 提交前, 处理非标准value
        for (const [key, value] of Object.entries(values)) {
            if (value instanceof moment) {
                values[key] = value.format("YYYY-MM-DDTHH:mm:ss.SSS");
            }
        }

        console.log('submit:', values);
        // 提交form
        axios.post(formArgs.submit, values).then(function (resp) {
            args.onSubmit(resp, values, formRef);
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <Form
            name={formArgs.name}
            ref={formRef}
            initialValues={formArgs.defaultValue}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            {formArgs.items.map((formItem) => {
                switch (formItem.type) {
                    case 'input':
                        return input(formItem);
                    case 'checkBox':
                        return checkBox(formItem);
                    case 'radio':
                        return radio(formItem);
                    case 'textArea':
                        return textArea(formItem);
                    case 'datePicker':
                        return datePicker(formItem);
                    case 'fastFill':
                        return fastFill(formRef, formItem);
                    default:
                        return <div>{formItem.name}</div>;
                }
            })}

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                <Button htmlType="reset">Reset</Button>
            </Form.Item>
        </Form>
    );
}

const input = formItem => {
    let _required = formItem.required;
    let _type = formItem.data;

    let item;
    if (_type === 'number') {
        item = <InputNumber/>;
    } else if (_type === 'password') {
        item = <Input.Password placeholder="input password"/>;
    } else {
        item = <Input/>;
    }

    return (
        <Form.Item
            key={formItem.name}
            name={formItem.name}
            label={formItem.label}
            rules={[{required: _required}]}
        >
            {item}
        </Form.Item>
    );
};

const textArea = formItem => {
    const _required = formItem.required;
    const _maxLength = formItem.data;

    return (
        <Form.Item key={formItem.name} name={formItem.name} label={formItem.label} required={_required}>
            <Input.TextArea showCount maxLength={_maxLength}/>
        </Form.Item>
    );
};

const radio = formItem => {
    const _required = formItem.required;
    const _showGroup = formItem.showGroup;
    const _appendGroupName = formItem.appendGroupName;
    return (
        <Form.Item
            key={formItem.name}
            name={formItem.name}
            label={formItem.label}
            rules={[
                {
                    required: _required,
                    message: 'Please pick an item!',
                },
            ]}
        >
            <Radio.Group>
                {formItem.data.map((entity) => {
                    let group = entity.group;
                    return (
                        <Row key={`row-${group}`}>
                            {_showGroup ? <Button key={group} type="text"> {group}: </Button> : ''}
                            {entity.items.map((it) => {
                                const value = _appendGroupName ? `${group}:${it}` : it;
                                return (
                                    <Col key={`col-${value}`}>
                                        <Radio.Button key={value} style={{lineHeight: '32px'}} value={value}>
                                            {it}
                                        </Radio.Button>
                                    </Col>
                                );
                            })}
                        </Row>
                    );
                })}
            </Radio.Group>
        </Form.Item>
    );
};

const checkBox = formItem => {
    const _required = formItem.required;
    const _showGroup = formItem.showGroup;
    const _appendGroupName = formItem.appendGroupName;
    return (
        <Form.Item
            key={formItem.name}
            name={formItem.name}
            label={formItem.label}
            rules={[
                {
                    required: _required,
                    message: 'Please pick an item!',
                },
            ]}
        >
            <Checkbox.Group>
                {formItem.data.map((entity) => {
                    let group = entity.group;
                    return (
                        <Row key={`row-${group}`}>
                            {_showGroup ? <Button key={group} type="text">{group}: </Button> : ''}
                            {entity.items.map((it) => {
                                const value = _appendGroupName ? `${group}:${it}` : it;
                                return (
                                    <Col key={`col-${value}`}>
                                        <Checkbox key={value} style={{lineHeight: '32px'}} value={value}>
                                            {it}
                                        </Checkbox>
                                    </Col>
                                );
                            })}
                        </Row>
                    );
                })}
            </Checkbox.Group>
        </Form.Item>
    );
};

const datePicker = formItem => {
    const _required = formItem.required;
    let time = conventLocalDateTimeToMoment(formItem.data);

    return (
        <Form.Item
            key={formItem.name}
            name={formItem.name}
            label={formItem.label}
            rules={[
                {
                    required: _required,
                    message: 'Please pick an item!',
                },
            ]}
            initialValue={time}
        >
            <DatePicker
                showTime={{format: 'HH:mm:ss'}}
                // format 影响的只是展示数据
                format="YYYY-MM-DD HH:mm:ss"
            />
        </Form.Item>
    );
};

const fastFill = (formRef, formItem) => (
    <Form.Item key={formItem.name} label={formItem.label}>
        {formItem.data.map((entity) => {
            const name = entity.name;
            const data = entity.data;

            return (
                <Button
                    key={name}
                    type="link"
                    htmlType="button"
                    onClick={() => formRef.current.setFieldsValue(data)}
                >
                    {name}{' '}
                </Button>
            );
        })}
    </Form.Item>
);
