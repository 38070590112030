import React, {useEffect, useState} from 'react';
import {Form, Popconfirm, Table, Typography} from 'antd';
import EditableCell from "./EditableCell";
import qs from "qs";

const axios = require('axios');

let originData = [];

const getRandomUserParams = params => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
});

const TemplateTableEditable = (props) => {
    const args = props.args;
    const recordKey = (record) => args.keyColumn(record);

    const [form] = Form.useForm();
    const [data, setData] = useState(originData);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });
    const [listParams, setListParams] = useState({"pagination": pagination});
    const [editingKey, setEditingKey] = useState('');

    const isEditing = (record) => recordKey(record) === editingKey;

    const handleTableChange = (pagination, filters, sorter) => {
        const _sort = Array.isArray(sorter) ? sorter : [sorter,];
        const _filter = {};
        Object.keys(filters).forEach(key => _filter[key] = filters[key]?.join(','))
        fetch({
            sortField: _sort.map(f => f.field).join(','),
            sortOrder: _sort.map(f => f.order).join(','),
            pagination,
            ..._filter,
        });
    };

    const fetch = (params = {}) => {
        setListParams(params);
        loadList(params);
    };

    const loadList = (params) => {
        params = params ? params : listParams;
        setLoading(true);
        let api = props.args.dataApi;
        api = api.indexOf('?') !== -1 ? api.concat('&') : api.concat('?');

        axios.get(`${api}${qs.stringify(getRandomUserParams(params))}`)
            .then(function (resp) {
                const data = resp.data;
                setData(data.data);
                setPagination({
                    ...params.pagination,
                    total: data.total,
                });
                setLoading(false);
            });
    }

    const edit = (record) => {
        form.setFieldsValue({
            name: '',
            age: '',
            address: '',
            ...record,
        });
        setEditingKey(recordKey(record));
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key) => {
        try {
            // 只加载更新过的值, row是新值, data[index]是老值
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === recordKey(item));

            if (index > -1) {
                const item = data[index];
                // 比较新值老值差别, 然后提交update变更老值, 之后重新加载table
                const update = {id: item.id};
                let noChange = true;
                Object.keys(row).forEach(field => {
                    if (row[field] !== item[field]) {
                        update[field] = row[field];
                        noChange = false;
                    }
                })

                if (noChange) {
                    setEditingKey('');
                    return;
                }

                console.log(update);
                await axios.post(args.updateApi, update).then(resp => {
                    if (resp.data.ok) {
                        loadList();
                        setEditingKey('');
                    }
                });

            } else {
                console.log(`not find item on ${key}`)
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    useEffect(() => {
        loadList();
    }, []);// eslint-disable-line

    const editColumn = () => {
        return {
            title: '操作',
            dataIndex: 'operation',
            fixed: 'right',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable
                    ? (
                        <span>
            <Typography.Link onClick={() => save(recordKey(record))} style={{marginRight: 8,}}>保存</Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}><a href='/#'>取消</a></Popconfirm>
                        </span>
                    )
                    : (
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            编辑
                        </Typography.Link>
                    );
            },
        }
    };

    const columns = [...args.columns, editColumn()];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                rowClassName="editable-row"
                columns={mergedColumns}
                rowKey={record => record.id}
                dataSource={data}
                loading={loading}
                onChange={handleTableChange}
                pagination={{
                    ...pagination,
                    onChange: cancel,
                }}
                scroll={{scrollToFirstRowOnChange: true, x: 1080, y: 1080}}
            />
        </Form>
    );
};

export default TemplateTableEditable;