import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter as Router, Route, Routes} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import {App} from "./betterlive/App";
import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';
import {ConfigProvider} from "antd";
import {init} from "./init";

import {Welcome} from "./betterlive/page/Welcome";
import {menuRoutes} from "./betterlive/config/route";
import NoFoundPage from "./betterlive/page/Error/404";
import {Login} from "./betterlive/page/User/Login";

// 初始化配置
init();

ReactDOM.render(
    <React.Fragment>
        <ConfigProvider locale={zhCN}>
            <Router>
                <Routes>
                    <Route path={"/login"} element={<Login/>}/>
                    <Route path={"/"} element={<App/>}>
                        <Route index element={<Welcome/>}/>
                        {/*菜单路由*/}
                        {menuRoutes}
                    </Route>
                    <Route path="*" element={<NoFoundPage/>}/>
                </Routes>
            </Router>
        </ConfigProvider>
    </React.Fragment>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
