import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import axios from "axios";
import {arrayMoveImmutable} from "array-move";
import {Table} from "antd";
import {useEffect, useState} from 'react';
import "./DragSortTable.css";
import {MenuOutlined} from "@ant-design/icons";

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

/**
 * 排序拖拽的表格
 *
 * @param props 参数
 * props.queryArgs 会拼在 props.conf.dataUrl 后面, 作为查询数据的参数
 * props.conf.sortedUrl 会在排序完成后调用, 会把 id 集合作为body发送
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const DragSortTable = (props) => {
    const flush = props.flush;
    const args = props.queryArgs;
    const conf = props.conf;
    const dataUrl = conf.dataUrl;
    const sortedUrl = conf.sortedUrl;
    const columns = conf.columns;

    const [dataSource, setDataSource] = useState([]);

    useEffect(loadDate, []);

    function loadDate() {
        axios.get(`${dataUrl}${args}`).then(resp => {
            const data = resp.data;
            let i = 1;
            const datasource = data.data.map(it => ({...it, index: i++}));
            setDataSource(datasource);
        })
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        // console.log('sort end...')
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
                (el) => !!el,
            );
            // console.log('Sorted items: ', newData);
            setDataSource(newData);

            axios.post(sortedUrl, newData.map(it => it.id)).then(resp => {
                if (resp.data.ok && flush) {
                    flush();
                }
            });
        }
    };

    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({className, style, ...restProps}) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex((x) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    return (
        <Table
            pagination={false}
            dataSource={dataSource}
            columns={columns}
            rowKey="index"
            components={{
                body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                },
            }}
        />
    );
}

export const DragHandle = SortableHandle(() => (
    <MenuOutlined
        style={{
            cursor: 'grab',
            color: '#999',
        }}
    />
));