const init = () => {
    // npm启动的开发环境? 还是编译后的
    const prod = true;
    // HashRouter or BrowserRouter
    const hashRouter = true;

    return {
        baseURL: prod ? '' : '/api',
        loginUrl: hashRouter ? '/#/login' : '/login',
        welcomeUrl: hashRouter ? '/#/welcome' : '/welcome',
    }
}

export const env = init();
