import React from "react";
import {Outlet} from 'react-router';
import 'antd/dist/antd.min.css';
import {Layout} from 'antd';
import {Content, Header} from "antd/es/layout/layout";
// 以下是自定义组件
import BlMenu from './BaseMenu';
import {menus} from "./config/route";

export const App = () => (
    <Layout className="layout">
        <Header style={{padding: '5px', background: '#fff'}}>
            <div className="logo"/>
            <BlMenu menuRoutes={menus()}/>
        </Header>
        <Layout>
            <Content style={{padding: '5px',}}>
                <Outlet/>
            </Content>
        </Layout>
    </Layout>
);

