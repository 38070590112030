import React from 'react';
import {Link} from 'react-router-dom';
import 'antd/dist/antd.min.css';
import {Menu} from 'antd';

const BlMenu = (props) => {
    let menuRoutes = props.menuRoutes;

    const conventMenu = menu => menu.sub ? conventMenuGroup(menu) : conventMenuItem(menu);

    const conventMenuGroup = menuGroup => (
        <Menu.SubMenu key={menuGroup.name} title={menuGroup.name} icon={menuGroup.icon}>
            {
                menuGroup.sub.map(subMenu => conventMenu(subMenu))
            }
        </Menu.SubMenu>
    );

    const conventMenuItem = menuItem => (
        <Menu.Item key={menuItem.name} icon={menuItem.icon} disabled={menuItem.disable}>
            <Link to={menuItem.path}>{menuItem.name}</Link>
        </Menu.Item>
    )

    return (
        <Menu
            onClick={e => console.log('click ', e)}
            // style={{width: 256}}
            defaultSelectedKeys={['welcome']}
            mode="horizontal"
        >
            {menuRoutes.map(menu => conventMenu(menu))}
        </Menu>
    );
};

export default BlMenu;