import {
    AccountBookTwoTone,
    BookTwoTone,
    BugTwoTone,
    DollarCircleOutlined,
    EditTwoTone,
    SmileTwoTone,
    UnorderedListOutlined
} from "@ant-design/icons";
import {Welcome} from "../page/Welcome";
import {TemplateForm} from "../component/TemplateForm";
import * as CashFlow from "../page/CashFlow/CashFlowConfig";
import {TemplateTable} from "../component/TemplateTable";
import TemplateTableEditable from "../component/TemplateTableEditable";
import React from "react";
import {Route} from "react-router-dom";
import {flatConvent} from "../util/CommonUtil";
import {TodoThingGroup} from "../page/Todo/ToDo";

export function menus() {
    let notLogin = !localStorage.token;
    return [
        {name: "主页", path: "/welcome", element: <Welcome/>, icon: <SmileTwoTone/>},
        {
            name: "账本", icon: <DollarCircleOutlined/>, sub: [
                {
                    name: "创建",
                    path: "/cashFlow/add",
                    element: <TemplateForm args={CashFlow.formConfig()}/>,
                    icon: <BookTwoTone/>,
                    disable: notLogin
                },
                {
                    name: "查看",
                    path: "/cashFlow/list",
                    element: <TemplateTable args={CashFlow.tableConfig()}/>,
                    icon: <AccountBookTwoTone/>,
                    disable: notLogin
                },
                {
                    name: "编辑",
                    path: "/cashFlow/listEditable",
                    element: <TemplateTableEditable args={CashFlow.tableEditableConfig()}/>,
                    icon: <EditTwoTone/>,
                    disable: notLogin
                },
            ]
        },
        // {name: "测试组件", path: "/test", element: <ToDoThing pid={0}/>, icon: <BugTwoTone/>},
        {name: "todo", path: "/todo", element: <TodoThingGroup/>, icon: <UnorderedListOutlined/>},
        {name: "测试组件", path: "/test", element: <TodoThingGroup/>, icon: <BugTwoTone/>},

    ]
}

export const menuRoutes = flatConvent(menus(), it => it.sub,
    menu => <Route key={menu.name} path={menu.path} element={menu.element}/>);