import moment from "moment";
import {env} from "./env";

/**
 * 初始化程序的一些数据
 */
export function init() {
    moment.locale('zh-cn');

    const axios = require('axios');
    axios.defaults.baseURL = env.baseURL;
    axios.interceptors.request.use(function (config) {
        let token = localStorage.token;
        if (token) {
            config.headers.token = token;
        }
        return config;
    });
    axios.interceptors.response.use(
        resp => resp,
        error => {
            console.log(error)
        }
    )

}