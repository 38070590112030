import {Col, Divider, Input, Modal, Popconfirm, Row, Tag} from 'antd';
import {useState} from 'react';
import {
    CheckCircleTwoTone,
    DeleteOutlined,
    DownCircleOutlined,
    LoadingOutlined,
    MenuOutlined,
    PlusCircleOutlined,
    UpCircleOutlined
} from '@ant-design/icons';
import {ToDoThing} from "../../page/Todo/ToDo";
import {TemplateForm} from "../TemplateForm";
import {DragHandle, DragSortTable} from "../table/DragSortTable";
import axios from "axios";
import moment from "moment";
import {conventLocalDateTimeToMoment} from "../../util/CommonUtil";


export const TodoItem = (props) => {

    const todo = props.todo;
    const id = todo.id;
    const text = todo.thing;
    const finish = todo.finish;

    const deadlineText = todo.deadlineText;
    const timeout = todo.timeout;

    const onChange = props.changeFinish;
    const flush = props.flush;
    const showFinish = props.showFinish;

    const [expand, setExpand] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const closeDetail = () => setShowDetail(false);
    const [showSort, setShowSort] = useState(false);
    const flushSort = () => {
        setExpand(false);
        setTimeout(() => setExpand(true), 50);
    };

    const toggle = () => setExpand(!expand)
    const Del = () => <TodoDel id={id} thing={text} flush={flush}/>

    const color = () => {
        // 完成, 绿色
        if (finish) {
            return "green";
        }

        // 逾期, 红色
        if (timeout) {
            return "red";
        }
        const deadline = conventLocalDateTimeToMoment(todo.deadline);
        if (!deadline) {
            return "";
        }

        const now = moment();

        // 近三小时, 黄色
        const recent = deadline.isBefore(now.clone().add(3, 'hour'), 'hour');
        if (recent) {
            return "orange";
        }

        // 今天以后的, 黑色
        const future = deadline.isAfter(now, 'day');
        if (future) {
            return "";
        }

        // 今天, 但是宽裕的, 蓝色
        return "blue";
    }

    // 展开的时候, 显示 [排序按钮, 删除, 收起按钮]
    const sufOnExpand = <>
        <MenuOutlined onClick={() => setShowSort(true)}/>
        <Divider type="vertical"/>
        <Del/>
        <Divider type="vertical"/>
        <UpCircleOutlined onClick={toggle}/>
    </>
    // 收起的时候, 显示 [删除, 展开按钮]
    const sufNotExpand = <>
        <Tag color={color()} onClick={() => setShowDetail(true)}>{deadlineText}</Tag>
        <Del/>
        <Divider type="vertical"/>
        <DownCircleOutlined onClick={toggle}/>
    </>

    const pre = finish ? <CheckCircleTwoTone onClick={onChange}/> : <LoadingOutlined onClick={onChange}/>
    const suf = expand ? sufOnExpand : sufNotExpand;
    const sub = expand ?
        <Row><Col span={24}><ToDoThing pid={id} showFinish={showFinish} parent={text}/></Col></Row> : '';

    return <div>
        <Input size={'large'} bordered={false} readOnly="readonly" disabled={finish}
               prefix={pre} suffix={suf} value={text}
               onClick={() => setShowDetail(true)}
        />
        {sub}
        <Modal open={showDetail} onCancel={closeDetail} footer={null} closable={false} destroyOnClose={true}>
            {/*展示当前item的详细信息(可编辑)*/}
            <TodoEdit id={id} editFinish={() => {
                setShowDetail(false);
                flush();
            }}/>
        </Modal>
        <Modal open={showSort} onCancel={() => setShowSort(false)} footer={null} closable={false} destroyOnClose={true}>
            <DragSortTable conf={sortConfig} queryArgs={`/${id}?showFinish=${showFinish}`} flush={flushSort}/>
        </Modal>
    </div>
};

export const TodoAdd = (props) => {
    const pid = props.pid;
    const parent = props.parent;
    const clickAdd = props.clickAdd;

    const [thing, setThing] = useState("");

    const addThing = () => {
        console.log(`add ${thing} to ${pid}`)
        clickAdd(pid, thing);
        setThing("");
    };

    const add = <PlusCircleOutlined onClick={addThing}/>;

    return <div>
        <Input value={thing} size={'large'}
               placeholder={`添加子项到 ${parent ? parent : 'root'}`}
               allowClear={true}
               onPressEnter={addThing}
               onChange={e => setThing(e.target.value)}
               prefix={add}/>
    </div>

}

const TodoEdit = (props) => {
    const conf = {
        fromApi: `/todo_list/editForm/${props.id}`,
        onSubmit: (resp, fromValue, _from) => {
            props.editFinish();
        },
    }
    return <TemplateForm args={conf}/>
}

const TodoDel = (props) => {
    const id = props.id;
    const thing = props.thing;
    const flush = props.flush;

    const del = () => {
        axios.post(`/todo_list/del/${id}`).then(resp => {
            if (resp.data.ok) {
                flush();
            }
        })
    }

    return (
        <Popconfirm
            title={`${thing}`}
            icon={<DeleteOutlined/>}
            placement="topRight"
            okType="danger"
            okText="DELETE"
            onConfirm={del}
            // showCancel={false}
            cancelText="CANCEL"
            // onCancel={}
        >
            <DeleteOutlined/>
        </Popconfirm>
    )
}

const sortConfig = {
    dataUrl: "/todo_list/sort/list",
    sortedUrl: "/todo_list/sort/list",
    columns: [
        {
            title: '',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle/>,
        },
        {
            title: '待办事项',
            dataIndex: 'thing',
        },
        {
            title: '截止时间',
            dataIndex: 'deadlineText',
        },
    ],
}
