import {Modal} from 'antd';
import {env} from "../../../env";

export function FormConfig() {
  return {
    fromApi: '/auth/form',
    onSubmit: (resp, fromValue, _from) => {
      const token = resp.data.data;
      if (token) {
        localStorage.setItem('token', token);
        console.log('to welcome');
        // history.push('/welcome');
        window.location = env.welcomeUrl;
      } else {
        Modal.error({
          content: 'login failed',
        });
      }
    },
  };
}
