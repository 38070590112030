import {TodoAdd, TodoItem} from "../../component/todo/Todo";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Button, Card, Col, Divider, Input, Modal, Popconfirm, Row, Switch, Table} from "antd";
import {CheckOutlined, EditTwoTone, FolderAddOutlined, SettingOutlined} from "@ant-design/icons";
import {EditableInput} from "../../component/Editable";
import {DragHandle, DragSortTable} from "../../component/table/DragSortTable";
import {TemplateTable} from "../../component/TemplateTable";
import {conventLocalDateTimeToISOString} from "../../util/CommonUtil";

const defaultGroup = [{id: -1, name: '计划内'}, {id: 0, name: '默认'}];

export const TodoThingGroup = () => {
    const conf = {
        listUrl: "/todo_group/list",
        addUrl: "/todo_group/add",
        delUrl: "/todo_group/del",
        updateUrl: "/todo_group/editDetail",
    }

    const [data, setData] = useState(defaultGroup);
    const [activeBar, setActiveBar] = useState("-1");
    const [editable, setEditable] = useState(false);
    const [activeSort, setActiveSort] = useState(false);
    const [thingSort, setThingSort] = useState(false);
    // true: 近期完成也显示, false: 仅显示未完成
    const [showFinish, setShowFinish] = useState(true);

    const loadDate = () => {
        axios.get(`${conf.listUrl}`).then(resp => {
            const load = resp.data.data;

            const newDate = defaultGroup.concat(load);
            setData(newDate);
        })
    };

    useEffect(loadDate, [conf.listUrl]);

    const item2tab = it => ({
        key: `${it.id}`,
        name: it.name,
        tab: (<GroupTab delUrl={conf.delUrl} updateUrl={conf.updateUrl}
                        id={it.id} name={it.name} editable={editable}
                        flush={() => {
                            setActiveBar("0");
                            loadDate();
                        }}/>)
    });

    const tabList = data.map(item2tab);
    // 新增
    const addKey = "+++";
    tabList.push({key: addKey, tab: <FolderAddOutlined/>});
    // 配置
    const confKey = "conf.conf.conf";
    tabList.push({key: confKey, tab: <SettingOutlined/>});
    // 已完成
    const finishKey = "finish.finish.finish";
    const finishConf = {
        name: '已完成的Todo',
        dataApi: '/todo_list/finished',
        keyColumn: record => record.id,
        columns: [
            {title: 'Todo', dataIndex: 'thing', key: 'thing'},
            {
                title: '完成时间',
                key: 'finishTime',
                render: it => it.finish ? conventLocalDateTimeToISOString(it.finishTime) : ''
            },
            {title: '截止时间', dataIndex: 'deadline', key: 'deadline', render: conventLocalDateTimeToISOString},
            {title: '周期', key: 'cycleNum', render: it => it.cycleNum ? `${it.cycleNum}${it.cycleUnit}` : ''},
        ]
    };
    tabList.push({key: finishKey, tab: <CheckOutlined/>});

    const confContext = (
        <div>

            <Conf
                // 编辑
                editable={editable} toggleEditable={() => setEditable(!editable)}
                // 排序
                sortGroup={() => setActiveSort(true)}/>
            <Modal open={activeSort} onCancel={() => setActiveSort(false)} footer={null} closable={false}
                   destroyOnClose={true}>
                <DragSortTable conf={groupSortConfig} queryArgs={''} flush={loadDate}/>
            </Modal>
        </div>
    )

    const RootThing = () => {
        return (
            <>
                <ToDoThing pid={activeBar} showFinish={showFinish}
                           parent={`[${tabList.find(it => it.key === activeBar).name}]`}/>
                {/*点击排序根todo*/}
                <Button onClick={() => setThingSort(true)}>排序</Button>
                <Modal open={thingSort} onCancel={() => setThingSort(false)} footer={null} closable={false}
                       destroyOnClose={true}>
                    <DragSortTable conf={rootThingSortConfig} queryArgs={`/${activeBar}`} flush={loadDate}/>
                </Modal>
                <Divider type="vertical"/>
                {/*开启: 近期完成也显示;关闭: 仅显示未完成的*/}
                <Switch checkedChildren="近期完成" unCheckedChildren="仅未完成" defaultChecked={showFinish}
                        onChange={setShowFinish}/>
            </>
        )
    }

    const showContext = () => {
        if (activeBar === addKey) return <AddGroup url={conf.addUrl} flush={loadDate}/>;
        if (activeBar === confKey) return confContext;
        if (activeBar === finishKey) return <TemplateTable args={finishConf}/>;

        return <RootThing/>;
    }

    return <Card
        tabList={tabList}
        activeTabKey={activeBar}
        onTabChange={setActiveBar}
    >
        {showContext()}
    </Card>;

}

const Conf = props => {
    const columns = [
        {title: '项', dataIndex: 'name'},
        {title: '配置', dataIndex: 'conf'},
    ]

    const data = [
        {key: 1, name: '允许编辑组', conf: <Switch checked={props.editable} onChange={props.toggleEditable}/>},
        {key: 2, name: '排序组', conf: <Button onClick={props.sortGroup}>点击排序</Button>},
    ]

    return <Table columns={columns} dataSource={data}/>
}

const groupSortConfig = {
    dataUrl: "/todo_group/sort/list",
    sortedUrl: "/todo_group/sort/list",
    columns: [
        {
            title: '',
            dataIndex: 'sortNo',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle/>,
        },
        {
            title: '组',
            dataIndex: 'name',
        },
    ],
}

const rootThingSortConfig = {
    dataUrl: "/todo_list/sort/list",
    sortedUrl: "/todo_list/sort/list",
    columns: [
        {
            title: '',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle/>,
        },
        {
            title: '待办事项',
            dataIndex: 'thing',
        },
        {
            title: '截止时间',
            dataIndex: 'deadline',
        },
    ],
}

const GroupTab = props => {
    // 不允许删除/编辑的组id
    const canNotDel = [0, 100000001];

    const id = props.id;
    const name = props.name;
    const disabled = canNotDel.indexOf(id) !== -1 || !props.editable;
    const delUrl = props.delUrl;
    const updateUrl = props.updateUrl;

    const del = () => {
        console.log(`del ${name}`);
        axios.post(`${delUrl}/${id}`).then(resp => {
            if (resp.data.ok) {
                props.flush();
            }
        })
    }

    const editName = <EditableInput text={name} editable={true}
                                    updateUrl={`${updateUrl}/${id}`}
                                    itemKey={'name'}
                                    flush={props.flush}
    />
    return (
        <Popconfirm
            title={editName}
            icon={<EditTwoTone/>}
            disabled={disabled}
            placement="top"
            okType="danger"
            okText="DELETE"
            onConfirm={del}
            // showCancel={false}
            cancelText="CANCEL"
        >
            <Button type="text">{name}</Button>
        </Popconfirm>)
}

const AddGroup = props => {
    const add = () => {
        if (value) {
            axios.post(`${props.url}/${value}`).then(resp => {
                if (resp.data.ok) {
                    props.flush();
                    setValue('');
                }
            });
        }
    };

    const [value, setValue] = useState("");

    return <>
        <Row>
            <Col span={22}>
                <Input size="large"
                       placeholder="创建组"
                       allowClear={true}
                       value={value}
                       onChange={e => setValue(e.target.value)}
                       onPressEnter={add}
                />
            </Col>
            <Col span={2}><Button size="large" type="primary" onClick={add}>添加</Button></Col>
        </Row>
    </>
}

export const ToDoThing = props => {
    const pid = props.pid;
    const parent = props.parent;
    const showFinish = props.showFinish;
    const [data, setData] = useState([]);

    useEffect(loadDate, [pid, showFinish]);

    function loadDate() {
        axios.get(`/todo_list/list/${pid}?showFinish=${showFinish}`).then(resp => {
            const data = resp.data;
            setData(data.data);
        })
    }

    const add = (pid, thing) => {
        axios.post("/todo_list/add", {pid: pid, thing: thing}).then(resp => {
            if (resp.data.ok) {
                loadDate();
            }
        });
    };

    const change = (id) => {
        axios.post("/todo_list/change", {id: id}).then(resp => {
            if (resp.data.ok) {
                loadDate();
            }
        });
    }

    return <Card hoverable={true}>
        {data.map(it => <TodoItem key={it.id} todo={it}
                                  showFinish={showFinish}
                                  changeFinish={() => change(it.id)}
                                  flush={() => loadDate()}
        />)}
        <TodoAdd pid={pid} parent={parent} clickAdd={(pid, thing) => add(pid, thing)}/>
    </Card>

};