import {Button, Card} from "antd";
import React, {Component} from "react";
import * as CashFlow from "./CashFlow/CashFlowConfig";
import {TemplateTable} from "../component/TemplateTable";
import moment from "moment";
import {env} from "../../env";

const axios = require('axios');

/**
 * 用户信息, 如果尚未登录, 就显示登录页, 否则显示用户信息页
 */
export class Welcome extends Component {

    state = {
        userinfo: {
            name: 'guest',
        },
        cashFlow: {
            cycle: 'd',
            context: '消费 0笔, 总额 0元',
        }
    };

    tabListCashFlow = [
        {key: 'd', tab: '日'},
        {key: 'w', tab: '周'},
        {key: 'm', tab: '月'},
        {key: 'q', tab: '季'},
        {key: 'y', tab: '年'},
    ];

    componentDidMount() {
        if (!localStorage.token) {
            console.log('please login!');
            return;
        }

        // 加载用户信息
        axios.get('/auth/user').then(resp => {
            this.setState({userinfo: resp.data.data})
        });

        // 加载消费信息
        this.onCFTabChange(this.state.cashFlow.cycle);
    }

    onCFTabChange = key => {
        axios.get('/cash_flow/cycle', {params: {cycle: key}}).then(resp => {
            let cashFlow = this.state.cashFlow;
            cashFlow.cycle = key;
            cashFlow.context = resp.data.data;
            this.setState({cashFlow: cashFlow});
        });
    };

    render() {
        // 有用户登录, 就用token去请求用户信息, 渲染用户页面
        const token = localStorage.token;
        if (!token) {
            window.location = env.loginUrl;
            window.location.reload()
            return <></>
        }

        const args = CashFlow.tableConfig();
        args.dataApi = args.dataApi + `?startTime=${moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')}&endTime=${moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')}`;

        return (
            <div>
                {/*用户卡片*/}
                <Card title="用户">
                    <p>你好 {this.state.userinfo.name}</p>
                    <Button type="primary" onClick={() => this.logout()}>注销</Button>
                </Card>
                {/*消费信息卡片*/}
                <Card
                    // style={{ width: '100%' }}
                    title="消费"
                    tabList={this.tabListCashFlow}
                    // activeTabKey={activeTabKey1}
                    onTabChange={key => {
                        this.onCFTabChange(key);
                    }}
                >
                    <Card>
                        {this.state.cashFlow.context}
                    </Card>
                    {/*近期消费记录*/}
                    <TemplateTable args={args}/>
                </Card>
            </div>);

    }

    logout() {
        localStorage.removeItem("token")
        localStorage.removeItem("username")
        window.location.reload()
    }
}


