import React, {Component} from 'react';
import 'antd/dist/antd.min.css';
import {Table} from 'antd';
import qs from 'qs';

const getRandomUserParams = params => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
});

const axios = require('axios');

export class TemplateTable extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            data: [],
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false,
        };
    }

    componentDidMount() {
        const {pagination} = this.state;
        this.fetch({pagination});
    }

    handleTableChange = (pagination, filters, sorter) => {
        const _sort = Array.isArray(sorter) ? sorter : [sorter,];
        const _filter = {};
        Object.keys(filters).forEach(key => _filter[key] = filters[key]?.join(','))
        this.fetch({
            sortField: _sort.map(f => f.field).join(','),
            sortOrder: _sort.map(f => f.order).join(','),
            pagination,
            ..._filter,
        });
    };

    fetch = (params = {}) => this.loadList(params, this);

    loadList(params, that) {
        this.setState({loading: true});
        let api = this.props.args.dataApi;
        api = api.indexOf('?') !== -1 ? api.concat('&') : api.concat('?');

        axios.get(`${api}${qs.stringify(getRandomUserParams(params))}`)
            .then(function (resp) {
                const data = resp.data;
                that.setState({
                    loading: false,
                    data: data.data,
                    pagination: {
                        ...params.pagination,
                        total: data.total,
                    },
                });
            });
    }

    render() {
        const {data, pagination, loading} = this.state;
        return (
            <Table
                columns={this.props.args.columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={this.handleTableChange}
                scroll={{scrollToFirstRowOnChange: true, x: 1080, y: 1080}}
            />
        );
    }

}