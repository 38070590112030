import {Modal, Tag} from "antd";
import React from "react";

export function formConfig() {
    return {
        fromApi: '/cash_flow/form',
        onSubmit: (resp, fromValue, _from) => {
            Modal.success({
                content: `save success, data: ${JSON.stringify(fromValue)}`,
            });
            _from.current.resetFields();
        },
    }
}

export function tableConfig() {
    return tableEditableConfig();
}

export function tableEditableConfig() {
    return {
        name: '可编辑的表格',
        dataApi: '/cash_flow/list',
        updateApi: '/cash_flow/update',
        keyColumn: record => record.id,
        columns: [
            // {title: 'id', dataIndex: 'id', key: 'id', fixed: 'left', width: 50,},
            {title: '金额', dataIndex: 'money', key: 'money', fixed: 'left', width: 85, sorter: {multiple: 2,},},
            {
                title: '类型', dataIndex: 'type', key: 'type', width: 76,
                filters: [{text: '支出', value: '支出',}, {text: '收入', value: '收入',}],
            },
            {title: '平台', dataIndex: 'platform', key: 'platform',},
            {title: '场景', dataIndex: 'scenes', key: 'scenes',},
            {title: '目的', dataIndex: 'purpose', key: 'purpose',},
            {
                title: '程度', dataIndex: 'degree', key: 'degree', sorter: {multiple: 1,},
                filters: [
                    {text: '必要', value: '必要',},
                    {text: '优化', value: '优化',},
                    {text: '一时兴起', value: '一时兴起',},
                    {text: '其他', value: '其他',}],
            },
            {
                title: '标签', dataIndex: 'tags', key: 'tags', render: tags => (
                    <>
                        {tags?.split(",").map(tag => {
                            return (<Tag key={tag}>{tag}</Tag>);
                        })}
                    </>
                ),
            },
            {title: '简要描述', dataIndex: 'description', key: 'description', editable: true,},
            {title: '交易时间', dataIndex: 'transactionHour', key: 'transactionHour', width: 190,},
            {title: '使用频率', dataIndex: 'usageFrequency', key: 'usageFrequency', editable: true,},
            {title: '备注', dataIndex: 'remark', key: 'remark', editable: true},
        ],
    }
}

